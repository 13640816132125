// Base Imports
import React, { useState } from 'react'
import Link from 'next/link'
// import Router from 'next/router'

// Package Imports
import { IMG_PLACEHOLDER } from '@components/utils/textVariables'
import FreeGiftChange from '@components/product/QuickView/FreeGiftChange'
import { priceFormat } from '@framework/utils/parse-util'
import { handleBrokenImageError, removeSucceedingSlug } from '@framework/utils/app-util'
import { IDeviceInfo } from '@components/ui/context'

interface ICartFreeGift {
  data: any
  key?: string | number
  size?: string | null | undefined
  deviceInfo: IDeviceInfo
  handleOpenFreeGiftModal?: () => void | undefined
  setSelectedFreeGift?: any
  basketPromos?: any
  isChannelStore?: boolean
}

const CartFreeGift = (props: ICartFreeGift) => {
  const {
    data,
    size,
    deviceInfo,
    handleOpenFreeGiftModal,
    setSelectedFreeGift,
    basketPromos,
    isChannelStore,
  } = props
  const [changeData, setChangeData] = useState<any>()

  function showChangeOption(product: any) {
    setChangeData(product)
  }

  return (
    <>
      {/* FREE GIFT PRODUCT */}
      <div
        className={`${
          isChannelStore
            ? 'px-0 pt-1 pb-0 mt-1 sm:px-0 sm:my-2 sm:py-2'
            : 'px-0 pb-3 pt-6 sm:px-0'
        } flex flex-col w-full`}
      >
        <div className="flex gap-3 align-middle rounded sm:gap-6 bg-cart-sidebar-green-light">
          <div className="flex-shrink-0">
            <div className="">
              <Link
                legacyBehavior
                passHref
                href={`/${removeSucceedingSlug(data?.slug)}`}
                as={`/${data?.slug}`}
              >
                <img
                  src={data?.image || IMG_PLACEHOLDER}
                  alt="Free Gift"
                  width={72}
                  height={128}
                  className="basket-image"
                  onError={(e: any) => handleBrokenImageError(e)}
                />
              </Link>
            </div>
          </div>
          <div className="flex flex-col flex-1 py-2 pr-4">
            <div className="flex justify-between items-start">
              <h3
                className="mb-2 overflow-x-hidden font-normal text-truncate-two-lines text-xs text-primary"
                style={{
                  maxWidth: '75%',
                }}
                title={data?.name}
              >
                {data?.name}
              </h3>
            </div>
            <div className="flex mb-2">
              <span className="inline-block font-semibold text-black text-12 text-primary sm:text-md">
                {data?.price?.currencySymbol}
                {Math.round(data?.price?.raw?.withTax)}
              </span>
              <span
                className="inline-block mx-2 font-normal line-through text-xs"
                style={{ color: 'rgba(0, 19, 37, 0.64)' }}
              >
                {priceFormat(data?.listPrice?.raw?.withTax)}
              </span>
              <span className="inline-block font-normal text-xs text-green-dark">
                Free Gift
              </span>
            </div>
            <div className="flex mt-1 justify-left gap-x-6">
              {data?.qty && data?.qty !== '' ? (
                <h4 className="text-xs text-primary">Qty: {data?.qty}</h4>
              ) : null}
              {size != null ? (
                <h4 className="text-xs text-primary">
                  Size: {size?.toUpperCase()}
                </h4>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <FreeGiftChange
        isQuickview={changeData}
        setQuickview={showChangeOption}
        productData={changeData}
        collectionName="Change Free Gift"
        deviceInfo={deviceInfo}
      />
    </>
  )
}

export default CartFreeGift
